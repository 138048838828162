import { attachImportMap } from "./importmap";

(async () => {
  try {
    await attachImportMap();

    const [envConfig, auth] = await Promise.all([import("@scm/envconfig"), import("@scm/auth")]);
    await auth.initKeycloak(envConfig.rtcConfig);
    await (await import("@scm/application-shell")).start();
  } catch (err) {
    document.getElementById("loading")?.remove();
    document.body.appendChild((document.getElementById("error") as HTMLTemplateElement).content);
    console.error("Could not load application", err);
  }
})();
